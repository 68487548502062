$theme-color-keys: (primary, secondary, highlight);

// These defaults should ONLY ever get used on IE, and even then only in instances when we havent given an
// element a color override via an additional IE specific class
$default-theme-colors: (
  primary: #455C51,
  secondary: #F4F4F4,
  highlight: #999999,
);

$default-theme-font-colors: (
  primary: #FFFFFF,
  secondary: #1C2121,
  highlight: #FFFFFF,
);

// Fallbacks are defined here for browsers that dont support CSS variables
// They'll receive some inline styles for theming certain elements, but otherwise dont
// have much in the way of theming
@mixin theme-color($colorname) {
  color: map-get($default-theme-colors, $colorname);
  color: var(--theme-#{$colorname}-color);
}

@mixin theme-font-color($colorname) {
  color: map-get($default-theme-font-colors, $colorname);
  color: var(--theme-#{$colorname}-font-color);
}

@mixin theme-background-color($colorname) {
  background-color: map-get($default-theme-colors, $colorname);
  background-color: var(--theme-#{$colorname}-color);
}

@mixin theme-border-color($colorname) {
  border-color: map-get($default-theme-colors, $colorname);
  border-color: var(--theme-#{$colorname}-color);
}
