@charset 'UTF-8';

// now lets style the cookie consent
@import 'settings/colors';
@import 'settings/variables';

#cc-main .cm--box {
  border-radius: 0px;
}

#cc-main .pm--box {
  border-radius: 0px;
}

#cc-main .cm__btn {
  @include theme-font-color(primary);
  @include theme-background-color(primary);
  border: none;
  border-radius: 0px;
}

#cc-main .cm__btn.cm__btn--secondary {
  color: #2d4156;
  background-color: #eaeff2;
}

#cc-main .pm__btn {
  @include theme-font-color(primary);
  @include theme-background-color(primary);
  border: none;
  border-radius: 0px;
}

#cc-main .pm__btn.pm__btn--secondary {
  color: #2d4156;
  background-color: #eaeff2;
}

#cc-main .cm__texts {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  padding: $tt-grid-size * 3 0 0;
}

#cc-main .cm__btns, #cc-main .cm__links {
  padding: $tt-grid-size * 3;
}

#cc-main .cm__desc, #cc-main .cm__title {
  padding: 0 $tt-grid-size * 3;
}

#cc-main .cm__btns {
  border-top: none !important;
}
